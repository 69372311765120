import axios from "axios";

const BASIC_URL = "https://n7-demo-cp-viewer.nseven.xyz/api/v1/viewer";

const getAllContents = () => {
    const url = BASIC_URL + "/contents";
    return axios.get(url);
}

const getCps = () => {
    const url = BASIC_URL + "/cps";
    return axios.get(url);
}

const getViewerByHash = (viewerHash) => {
    const url = BASIC_URL + "/" + viewerHash;
    return axios.get(url);
}

const getContentByContentId = (contentStateId) => {
    const url = BASIC_URL + "/contentState/" + contentStateId;
    return axios.get(url);
}

const getViewership = (contentStateId, viewerHash) => {
    const url = BASIC_URL + "/content/" + contentStateId + "/viewership/" + viewerHash;
    return axios.get(url);
}

const findViewer = (viewerMail) => {
    const encodedMail = encodeURIComponent(viewerMail);
    const url = BASIC_URL + "/mail/" + encodedMail;
    return axios.get(url);
}

const addViewer = (viewerMail) => {
    let newViewer = {
        viewer: viewerMail
    }
    const url = BASIC_URL + "/viewer";
    return axios.post(url, newViewer);
}

const createPurchaseState = (contentStateId, viewer) => {
    let info = {
        viewerId: viewer,
        contentStateId: contentStateId,
        views: 1
    }
    const url= BASIC_URL + "/viewership";
    return axios.post(url, info);
}
    
const addViews = (contentStateId, viewerId, views) => {
    let info = {
        viewerId: viewerId,
        contentStateId: contentStateId,
        views: views
    }
    const url = BASIC_URL + "/views";
    return axios.put(url, info);
}

const getMail = (contentStateId) => {
    const url=BASIC_URL + "/content/" + contentStateId + "/mails";
    return axios.get(url);
}
 
const updatePurchaseState = (contentViewerMail) => {
    const url=BASIC_URL + "/mails";
    return axios.put(url, contentViewerMail);
}

export {getAllContents, getCps, getViewerByHash, getContentByContentId, getViewership, findViewer, addViewer, createPurchaseState, addViews, getMail, updatePurchaseState};
