import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Foot from '../components/Foot';
import "../components/MainPanel.css";
import {getAllContents, getCps, getViewerByHash} from './ContentManip';
import {hoverSubmitHandler, unhoverSubmitHandler, hoverDeleteHandler, unhoverDeleteHandler} from '../components/ButtonManip';
import {calculateContainerHeight, calculateTableWidth} from '../components/ContainerDimensionManip';
import NoContent from '../components/NoContent';
import { all } from 'axios';
import Error1 from '../errorManip/Error1';

const Contents = ({translator, viewerHash}) => {
    const [cps, setCps] = useState([]);
    const [contents, setContents] = useState(null);
    const [contentHeight, setContentHeight] = useState("");
    const [tableContentNameWidth, setTableContentNameWidth] = useState("");
    const [tableEndDateWidth, setTableEndDateWidth] = useState("");
    const [viewerNotExistError, setViewerNotExistError] = useState(false);

    const removeExpiredContent = (contentsArray) => {
        const contentsToRemoveId = [];
        for (let i = 0; i < contentsArray.length; i++) {
            const content = contentsArray[i];
            const today = new Date();
            today.setHours(0,0,0,0);
            const endDate = new Date(content.service_end_date);
            endDate.setHours(0,0,0,0);
            if(today > endDate){
                contentsToRemoveId.push(content.content_state_sp_id);
            }
        }

        return contentsArray.filter(content => !contentsToRemoveId.includes(content.content_state_sp_id));
    }

    const setCpFilter = (availableContents, cpList) => {
        var selectedCp = sessionStorage.getItem("cpFilter");
        if(selectedCp!==null && selectedCp!=="all"){
            availableContents = availableContents.filter((content) => cpList.find(cp => cp.cp_id===content.content_provider).cp_name===selectedCp);
        }
        return availableContents;
    }

    useEffect(() => {
        const init = async () => {
            const viewerData = await getViewerByHash(viewerHash).then(res => res.data);
            if(viewerData===undefined || viewerData.length===0){
                setViewerNotExistError(true);
            }

            var cpList = await getCps().then(res => res.data);
            setCps(cpList);

            var allContents = await getAllContents().then(res => res.data);
            allContents = removeExpiredContent(allContents);
            allContents = setCpFilter(allContents, cpList);
            setContents(allContents);

            

            const heightValue = calculateContainerHeight();
            const contentNameWidthValue = calculateTableWidth("table-content-name") === 0 ? "auto" : calculateTableWidth("table-content-name") + "px";
            const endDateWidthValue = calculateTableWidth("table-end-date") === 0 ? "auto" : calculateTableWidth("table-end-date") + "px";
            if(heightValue !== contentHeight || contentNameWidthValue !== tableContentNameWidth || endDateWidthValue !== tableEndDateWidth){
                setContentHeight(heightValue);
                setTableContentNameWidth(contentNameWidthValue);
                setTableEndDateWidth(endDateWidthValue);
            }
        };

        init();
    }, []);

    const purchaseContentHandler = (contentStateId) => {
        return(
            <Link to={"/" + viewerHash +"/" + contentStateId + "/purchase"} >
                <button className='n7-button normal-button' style={{cursor: "pointer", width: "auto"}} onMouseEnter={hoverSubmitHandler} onMouseLeave={unhoverSubmitHandler}>{translator("buy_content_access")}</button>
            </Link>
        )
    }

    const watchContentHanlder = (contentStateId) => {
        return(
            <Link to={"/" + viewerHash + "/" + contentStateId} >
                <button className='n7-button normal-button' style={{cursor: "pointer", width: "auto"}} onMouseEnter={hoverSubmitHandler} onMouseLeave={unhoverSubmitHandler}>{translator("watch")}</button>
            </Link>
        )
    }

    const actionHander = (contentStateId) => {
        const contentViewership = sessionStorage.getItem(contentStateId);
        if(contentViewership!==null){
            const contentViewershipArray = JSON.parse(contentViewership);
            const viewerBoughtContent = contentViewershipArray.verification;
            if(viewerBoughtContent===true){
                return watchContentHanlder(contentStateId);
            } else {
                return purchaseContentHandler(contentStateId);
            }
        } else {
            return purchaseContentHandler(contentStateId);
        }
    }

    if(contents!==null){
        if(viewerNotExistError){
            return <Error1 translator={translator} />
        } else {
            if(contents.length !== 0){
                return (
                    <div className="wrapper">
                        <Navbar 
                            translator={translator}
                        />
                        <div className="main-panel-with-footer">
                            <div className="main-panel-container">
                                <Sidebar 
                                    translator={translator}
                                    cps={cps}
                                />
                                <div className="table-container" style={{maxHeight: contentHeight}}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{width: tableContentNameWidth}}><p style={{margin:0, width: "max-content"}}>{translator("content_name")}</p></th>
                                                <th scope="col" style={{width: tableEndDateWidth}}><p style={{margin: 0, width: "max-content"}}>{translator("end_date")}</p></th>
                                                <th scope="col">{translator("action")}</th>                     
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contents.map((content, id) => 
                                                <tr key = {content.content_unique_id} >
                                                    <td><p className="table-content-name" style={{margin: 0, width: "max-content"}}>{content.content_name}</p></td>
                                                    <td><p className="table-end-date" style={{margin:0, width: "max-content"}}>{new Date(content.service_end_date).toLocaleDateString(localStorage.getItem("i18nextLng"))}</p></td>
                                                    <td style={{whiteSpace: "nowrap" }}>
                                                        {actionHander(content.content_state_sp_id)}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Foot translator={translator} />
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="wrapper">
                        <Navbar 
                            translator={translator}
                        />
                        <div className="main-panel-with-footer">
                            <div className="main-panel-container">
                                <Sidebar 
                                    translator={translator}
                                    cps={cps}
                                />
                                <NoContent translator={translator} />
                            </div>
                            <Foot translator={translator} />
                        </div>
                    </div>
                )
            }
        }
    }
}


export default Contents;