import React, { Component } from "react";
import "../components/Error.css"

export default class Error1 extends Component {

    constructor(props) {
        super(props);

        this.translator = props.translator;
    }

    render(){
        return(
            <div className="error-container">
                <div className="error-main-panel">
                    <img className="error-img" src={require("../components/icons/error1.png")} />
                    <div className="error-info-panel">
                        <div className="error-type">
                            {this.translator("error_404")}
                        </div>
                        <div className="error-msg-panel">
                            <div className="error-img-title">
                                {this.translator("page_not_found")}
                            </div>
                            <div className="error-msg">
                                {this.translator("retry")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}