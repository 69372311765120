import React, { useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/paper-dashboard.scss?v=1.3.0';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { Route, Routes } from 'react-router-dom';
import useUser from './userLogin/useUser';
import SignUp from './userLogin/SignUp';
import SignIn from './userLogin/SignIn';
import Contents from './contents/Contents';
import Content from './contents/Content';
import BuyContent from './contents/BuyContent';
import { useTranslation } from 'react-i18next';
import Error1 from './errorManip/Error1';
import Error3 from './errorManip/Error3';
import useToken from './contents/useToken';


function App() {

    const { user, setUser } = useUser();
    const { setToken } = useToken();
    const { t } = useTranslation();

    if(!user){
        return(
			<div className="App">
    	    	<Routes>
					<Route path="*" element={<SignIn setUser={setUser} translator={ t } />} />
					<Route path="/sign-up" element={<SignUp setUser={setUser} translator={ t } />} />
    	    	</Routes>
    		</div>
		) 
    } 

    try {
		const n7sdkInstance = window.N7SDK;
		const n7sdk = new n7sdkInstance();
		n7sdk.CpViewerAuth.userSession();
	} catch(error){
		if(error.message==="expired"){
			sessionStorage.removeItem("user");
        	window.location.assign("/");
		}
	}

    const url = window.location.pathname;
    const urlArray = url.split("/");
    const viewerHash = urlArray[1];
    return (
        <div className="App">
            <Routes>
                <Route path="/:viewerHashId" element={<Contents translator={t} viewerHash={viewerHash} />} />
                <Route path="/:viewerHashId/contents" element={<Contents translator={t} viewerHash={viewerHash} />} />
                <Route path='/:viewerHashId/:contentStateId' element={<Content translator={t} viewerHash={viewerHash} />} />
                <Route path='/:viewerHashId/:contentStateId/purchase' element={<BuyContent translator={t} setToken={setToken} viewerHash={viewerHash} />} />
                <Route path="*" element={<Error1 />} />
				<Route path="/:viewerHashId/bcError" element={<Error3 />} />
            </Routes>
        </div>
    );
}

export default App;
