import React, { Component } from "react";

export default class Error3 extends Component {

    constructor(props) {
        super(props);

        this.translator=props.translator;
        this.errorType=props.errorType;
        this.errorMsg=props.errorMsg;
    }

    render(){
        return(
            <div className="error-container">
                <div className="error-main-panel">
                    <img className="error-img" src={require("../components/icons/error3.png")} />
                    <div className="error-info-panel">
                        <div className="error-type">
                            {this.errorType}
                        </div>
                        <div className="error-msg-panel">
                            <div className="error-img-title">
                                
                            </div>
                            <div className="error-msg">
                                {this.errorMsg==="" ? "" : this.errorMsg + "."} 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}