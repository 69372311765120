import React from "react";
import "./Foot.css"

const Foot = ({translator}) => {
    return(
        <div className="footer">
            <div className="footer-left-panel">
                <div className="footer-left-panel-about-n7">
                    <a id="about-n7-link" href="#">
                        {translator('about_n7_project')}
                    </a>
                </div>
                <div className="footer-left-panel-contact">
                    <a id="contact-link" href="#">
                        {translator('contact_n7')}
                    </a>
                </div>
            </div>
            <div className="footer-right-panel">&copy; {1900 + new Date().getYear()}, v1, {translator('made_by_n7_development_team')}</div>
        </div>
    )
}

export default Foot;