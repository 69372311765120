import orgNameNoStyleHandler from "./OrgNameNoStyleManip"

const getCpCode = (cp) => {
    switch (cp) {
        case "OM":
            return "cp1"
        
        case "Real de Madrid":
            return "cp1"

        case "PSG":
            return "cp2"

        case "FC Barcelone":
            return "cp2"
    
        default:
            break;
    }
}

const getContentCode = (contentId, cp) => {
    const cpArray = cp.split(" ");
    const cpString = cpArray.join("-");
    const contentCode = contentId + "-" + orgNameNoStyleHandler(cpString);
    return contentCode;
}

export {getCpCode, getContentCode};