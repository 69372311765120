import React, { useState, useEffect } from "react";

const CpFilter = ({translator, activeFilterHandler, activeFilter, deactiveFilter, hoverHandler, unhoverHandler, cps, selectCp}) => {

    const [cpList, setCpList] = useState([]);

    useEffect(() => {
        const cpNames = cps.map(cp => cp.cp_name);
        setCpList(["all", ...cpNames]);
    }, [cps])

    return (
        <div className="sidebar-filter" id="cp-filter">
            <div className="sidebar-filter-title">
                {translator("content_provider")}
            </div>
            <div className="sidebar-filter-container">
                {
                    cpList.map(
                        cp => (
                            activeFilterHandler(cp) ? activeFilter(cp) : deactiveFilter(cp)
                        )
                    )
                }
            </div>
            
        </div>
    )
}

export default CpFilter;