import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Nav, NavItem, Dropdown, DropdownToggle, DropdownMenu,DropdownItem} from "reactstrap";
import languages from "../internationalization/languages";
import i18next from "i18next";
import "./Navbar.css";

const Navbar = () => {

    const [languageSelectionOpened, setLanguageSelectionOpened] = useState(false);

    const languageSelectionHandler = () => {
        setLanguageSelectionOpened(!languageSelectionOpened);
    }

    const logoutHandler = () => {
        // Clear all items in sessionStorage
        sessionStorage.clear();
        window.location.assign("/");
    }

    return (
       <div className="navbar">
           <Nav navbar className="navbar-right-panel">
               <Dropdown nav isOpen={languageSelectionOpened} toggle={languageSelectionHandler}>
                   <DropdownToggle nav>
                       <img className="navbar-language-selector" src={require("./icons/globe.png")} alt="nav-globe" />
                   </DropdownToggle>
                   <DropdownMenu end>
                       {
                           Object.keys(languages).map(
                               language => (
                                   <DropdownItem key={ language } onClick={() => i18next.changeLanguage(language) } > 
                                       { languages[language].name } 
                                   </DropdownItem>
                               )
                           )
                       }
                   </DropdownMenu>
               </Dropdown>
               <NavItem>
                    <Link className="nav-link btn-rotate" onClick={logoutHandler}>
                        <img className="navbar-logout" src={require("./icons/logout.png")} alt="nav-logout" />
                    </Link>
                </NavItem>
           </Nav>
       </div>
    )
}

export default Navbar;