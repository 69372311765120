import React, { useState, useEffect } from "react";
import "./Sidebar.css";
import CpFilter from "./CpFilter";
import { calculateContainerHeight } from "./ContainerDimensionManip";

const Sidebar = ({translator, cps}) => {

    const [sidebarHeight, setSidebarHeight] = useState("");
    
    useEffect(() => {
        const sidebarHeight = calculateContainerHeight();
        setSidebarHeight(sidebarHeight);
    }, []);

    const selectCp = (selectedCp) => {
        sessionStorage.setItem("cpFilter", selectedCp);
        window.location.reload();
    }

    const activeFilterHandler = (filterId) => {
        const selectedCp = sessionStorage.getItem("cpFilter");
        return selectedCp===null ? filterId==="all" : filterId===selectedCp;
    }

    const activeFilter = (filter) => {
        return (
            <button id={filter} key={filter} className="selected-tag selected-tag-text" style={{border: "none"}}>
                {filter==="all" ? translator(filter) : filter}
            </button>
        )
    }

    const deactiveFilter = (filter) => {
        return (
            <button id={filter} key={filter} className="normal-tag normal-tag-text" style={{border: "none"}} onMouseEnter={e => {hoverHandler(e)}} onMouseLeave={e => {unhoverHandler(e)}} onClick={() => {selectCp(filter)}}>
                {filter==="all" ? translator(filter) : filter}
            </button>
        )
    }

    const hoverHandler = (event) => {
        // event.target.style.color = deco.mainColor;
        event.target.classList.add("selected-tag");
        event.target.classList.remove("normal-tag");
        // event.target.style.boxShadow = deco.shadowColor;
    }

    const unhoverHandler = (event) => {
        // event.target.style.color = deco.secondaryColor;
        event.target.classList.add("normal-tag")
        event.target.classList.remove("selected-tag");
        // event.target.style.boxShadow = "unset";
    }

    return (
        <div className="sidebar-container" style={{maxHeight: sidebarHeight}}>
            <CpFilter
                translator={translator} 
                activeFilterHandler={activeFilterHandler} 
                activeFilter={activeFilter}
                deactiveFilter={deactiveFilter}
                hoverHandler={hoverHandler}
                unhoverHandler={unhoverHandler}
                cps={cps}
                selectCp={selectCp}
            />
        </div>
    )
}

export default Sidebar;