const hoverSubmitHandler = (event) => {
    event.target.classList.remove("normal-button");
    event.target.classList.add("normal-button-hover");
}

const unhoverSubmitHandler = (event) => {
    event.target.classList.remove("normal-button-hover");
    event.target.classList.add("normal-button");
}

const hoverCancelHandler = (event) => {
    event.target.classList.remove("cancel-button");
    event.target.classList.add("cancel-button-hover");
}

const unhoverCancelHandler = (event) => {
    event.target.classList.remove("cancel-button-hover");
    event.target.classList.add("cancel-button");
}

const hoverDeleteHandler = (event) => {
    event.target.classList.remove("delete-button");
    event.target.classList.add("delete-button-hover");
}

const unhoverDeleteHandler = (event) => {
    event.target.classList.remove("delete-button-hover");
    event.target.classList.add("delete-button");
}

export {hoverSubmitHandler, unhoverSubmitHandler, hoverCancelHandler, unhoverCancelHandler, hoverDeleteHandler, unhoverDeleteHandler};