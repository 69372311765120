import { useState } from "react";

const useToken = () => {
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        const token = JSON.parse(tokenString);
        return token;
    }
    const [token, setToken] = useState(getToken());
    const saveToken = (token, contentStateId) => {
        sessionStorage.setItem(contentStateId, JSON.stringify(token));
        setToken(token.token);
    };
    return {
        setToken: saveToken,
        token
    }
}

export default useToken;