import { hoverSubmitHandler, unhoverSubmitHandler } from "./ButtonManip";
import {ReactComponent as Loader} from "./icons/loading.svg";
import "./LoadingButton.css"

const LoadingButton = ({onSubmit, text, loading, disabled}) => {
    return (
        <button className="n7-button normal-button submit-button" onMouseEnter={hoverSubmitHandler} onMouseLeave={unhoverSubmitHandler} onClick={onSubmit} disabled={disabled}>
            {!loading ? text : <Loader className="spinner" style={{background:"unset"}}/>}
        </button>
    )
}

export default LoadingButton;