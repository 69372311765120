import React from "react";
import "./Title.css"

const Title = ({translator, title}) => {
    const purchaseMsgHandler = () => {
        const url = window.location.href;
        const urlArray = url.split("/");
        if(urlArray.includes("buyContent")){
            return(
                <div className="purchase-msg">{translator("purchase_msg") + "."}</div>
            )
        }
    }

    return(
        <div className="title-panel">
            <div className="title-panel-container">
                <div className="title">{title}</div>
                <div className="content-info">
                    {purchaseMsgHandler()}
                </div>
            </div>
        </div>
    )
}

export default Title;